import { WorkshopReservation } from "@/models/workshop-reservation.model";
import BaseService from "./base-service";

class WorkshopReservationsService extends BaseService<WorkshopReservation> {
  get endpoint() {
    return "/workshop-reservations";
  }
}

export default new WorkshopReservationsService();
